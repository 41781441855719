@import (reference) './vars.less';
@import (reference) './actions.less';

/**
    Class Reference

    .uf-avatar;

    Modifiers: small, large, bottom

*/

//---------------------------------------
//         Sizes

:root, .default {
    --avatar-size: 2.5rem;
    --avatar-icon-size: @icon-size;
    --avatar-font-size: @font-size;

    --avatar-background: transparent;
}

.large {
    --avatar-size: 3.75rem;
    --avatar-icon-size: @icon-size-lg;
    --avatar-font-size: @font-size-lg;
}

.small {
    --avatar-size: 2rem;
    --avatar-icon-size: @icon-size-sm;
    --avatar-font-size: @font-size-sm;

}

.x-small {
    --avatar-size: 1.5rem;
    --avatar-icon-size: @icon-size-xs;
    --avatar-font-size: @font-size-xs;
}

//---------------------------------------
//         Background colour

.uf-avatar.accent,
.uf-avatar-btn.accent {
    background: @brand-accent;
}

.uf-avatar.info,
.uf-avatar-btn.info {
    background: @info;
}

.uf-avatar.success,
.uf-avatar-btn.success {
    background: @success;
}

.uf-avatar.warning,
.uf-avatar-btn.warning {
    background: @warning;
}

.uf-avatar.error,
.uf-avatar-btn.error {
    background: @error;
}

.uf-avatar.disabled,
.uf-avatar-btn.disabled {
    background: @gray-20;
}

//---------------------------------------
//         Base style

.uf-avatar,
.uf-avatar-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: var(--avatar-size);
    width: var(--avatar-size);
    min-height: var(--avatar-size);
    min-width: var(--avatar-size);

    background: var(--avatar-background, @brand-accent);

    outline: 0;
    border-width: 0px;
    border-radius: 50%;
    overflow: hidden;

    outline: 0;
    line-height: initial;
    color: white;
    font-size: var(--avatar-font-size);
    text-decoration: none;

    uf-icon {
        fill: white;
        margin: auto;

        height: var(--avatar-icon-size);
        width: var(--avatar-icon-size);
    }

    img {
        height: 100%;
        max-width: 100%;
    }

}

.uf-avatar-btn {
    cursor: pointer;
}


@import (reference) './vars.less';

:root {
  --badgeColour: @error;
}

.uf-badge.secondary {
  --badgeColour: @warning;
}

.uf-badge {

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: @line-height-sm;
  min-width: 24px;
  padding: 6px;

  background: var(--badgeColour);
  border-radius: 1.25rem;
  transform: scale(0);
  transition: transform 225ms;
  pointer-events: none;

  color: white;
  font-size: @font-size-xs;
  line-height: @font-size-xs;
  font-weight: 600;

  fill: white !important;

  &.visible {
    transform: scale(1);
  }

}

uf-icon.uf-badge {
  padding: @space-2;
}

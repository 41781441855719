@import (reference) 'vars.less';

.uf-color-swatch {
    width: var(--swatch-size, @space-7);
    height: var(--swatch-size, @space-7);

    // To ensure margins don't extend beyond container
    overflow: auto;

    box-shadow: inset 0 0 0 1px @gray-10;
    background: white;
    border-radius: @border-radius;
}

//---------------------------------------
//         Sizes

.default {
    --swatch-size: @space-7;
}

.large {
    --swatch-size: @space-10;
}

.small {
    --swatch-size: @space-5;
}

.x-small {
    --swatch-size: 1.5rem;
}
/* You can add global styles to this file, and also import other style files */
@import './style-resets.css';

@font-face {
  font-family: 'Product Sans';
  src: url(assets/Product-Sans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Product Sans';
  font-weight: bold;
  src: url(assets/Product-Sans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: url(assets/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: url(assets/OpenSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  src: url(assets/OpenSans-Italic.ttf) format('truetype');
}

/** Library styles */
@import '../../@unifii/library/styles/vars.less';
@import '../../@unifii/library/styles/actions.less';
@import '../../@unifii/library/styles/app-bar.less';
@import '../../@unifii/library/styles/avatars.less';
@import '../../@unifii/library/styles/badges.less';
@import '../../@unifii/library/styles/box.less';
@import '../../@unifii/library/styles/box-model.less';
@import '../../@unifii/library/styles/buttons.less';
@import '../../@unifii/library/styles/button-groups.less';
@import '../../@unifii/library/styles/cards.less';
@import '../../@unifii/library/styles/color-swatch.less';
@import '../../@unifii/library/styles/chips.less';
@import '../../@unifii/library/styles/container.less';
@import '../../@unifii/library/styles/divider.less';
@import '../../@unifii/library/styles/form.less';
@import '../../@unifii/library/styles/images.less';
@import '../../@unifii/library/styles/inputs.less';
@import '../../@unifii/library/styles/grid.less';
@import '../../@unifii/library/styles/layout.less';
@import '../../@unifii/library/styles/lists.less';
@import '../../@unifii/library/styles/lozenge.less';
@import '../../@unifii/library/styles/surfaces.less';
@import '../../@unifii/library/styles/tables.less';
@import '../../@unifii/library/styles/typography.less';
@import '../../@unifii/library/styles/content.less';


html {
  box-sizing: border-box;

  font-size: 1em;
  color: @text-color;
  font-variant-ligatures: none;
}

html,
body {
  width: 100%;
  height: 100%;

  overflow: hidden;
}

// Required for IOS rubber banding issue
// Based on ionic's solution
body {
  position: fixed;
  margin: 0;
  padding: 0;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
}

sc-app {
  position: absolute;
  display: block;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
}

@media print {

  html,
  body,
  sc-app,
  .page__content,
  ng-component {
    position: static !important;

    overflow: initial;
  }

  sc-header,
  sc-menu,
  sc-block {
    display: none !important;
  }

}
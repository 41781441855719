//---------------------------------------
//         Colors


.brand-bg {
    background: @brand;
}

.brand-accent-bg{
    background: @brand-accent;
}

.primary-action-bg{
    background: @primary-action;
}

.secondary-action-bg{
    background: @secondary-action;
}

.group-bg{
    background: @group;
}

.group--child-bg{
    background: @group--child;
}

.group--second-level-bg{
    background: @group--second-level;
}

.group--third-level-bg{
    background: @group--third-level;
}

.group--fourth-level-bg{
    background: @group--second-level;
}

.info-bg {
    background: @info;
}

.success-bg {
    background: @success;
}

.warning-bg {
    background: @warning;
}

.error-bg {
    background: @error;
}